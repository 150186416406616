<template>
  <b-card>
    <!-- <b-row class="mb-2 justify-content-between">
      <b-col cols="12" lg="3" class="d-flex align-items-center">
        <router-link
          to="/create-business-group"
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Create Business Group
        </router-link>
      </b-col>
    </b-row> -->
    <b-row class="justify-content-start">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search: Order ID or Job Title"
            v-model="filter.search"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="search">Transaction Status:</label>
          <v-select
            v-model="filter.transactionStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="status"
            :reduce="(transactionStatus) => transactionStatus.value"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="start-date">Start Date:</label>
          <flat-pickr
            id="start-date"
            v-model="filter.startDate"
            class="form-control"
            :config="{
              dateFormat: 'Y-m-d',
            }"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="end-date">End Date:</label>
          <flat-pickr
            id="end-date"
            v-model="filter.endDate"
            class="form-control"
            :config="{
              dateFormat: 'Y-m-d',
            }"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :transactionData="transactionData"
      :is-loading="isLoading"
      :get-data="getData"
      :delete-item="deleteItem"
      :current-page="currentPage"
    />
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Table from "@/components/transaction/Table.vue";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    vSelect,
    flatPickr,
    // Modal,
  },
  //   directives: {
  //     "b-modal": VBModal,
  //     Ripple,
  //   },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      transactionData: {},
      status: [
        { value: "settlement", name: "Settlement" },
        { value: "pending", name: "Pending" },
        { value: "expire", name: "Expire" },
        { value: "deny", name: "Deny" },
        { value: "cancel", name: "Cancel" },
        { value: "capture", name: "Capture" },
      ],
      filter: {
        perPage: 10,
        transactionType: "income",
      },
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      this.filter.page = page;
      this.$http
        .get("/admin/transaction", {
          params: this.filter,
        })
        .then((response) => {
          this.result = response.data.meta;
          this.transactionData = response.data.data;
          this.currentPage = response.data.meta.page;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Business Group?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/admin/bussiness-group/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Business Group successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.message
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
